<template>
    <v-app>
        <v-dialog v-model="showDialog" max-width="800px">
            <v-card>
                <v-card-title>
                    <span>Cargar Dtes</span>
                </v-card-title>
                <v-card-text>
                    <v-simple-table
                        fixed-header
                        height="400px"
                    >
                        <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-center">
                                    Order
                                </th>
                                <th class="text-center">
                                    Biller
                                </th>
                                <th class="text-center">
                                    Invoice number
                                </th>
                                <th class="text-center">
                                    URL Dte
                                </th>
                                <th class="text-center">
                                    Subir Archivo
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(order, index) in orders" :key="index" >
                                <td>{{ order.order_reference }}</td>
                                <td><v-select :items="billersList" v-model="order.biller"></v-select></td>
                                <td><v-text-field label="invoice number" v-model="order.invoice_number"></v-text-field></td>
                                <td><v-text-field label="url archivo" v-model="order.url" readonly></v-text-field></td>
                                <td>
                                    <v-file-input
                                        v-model="orders[index].file" color="deep-purple accent-4" label="Subir dte" placeholder="Sube una archivo formato pdf,jpeg o png."
                                        prepend-icon="mdi-paperclip" outlined :show-size="1000" @change="uploadFile(index)" :disabled="loading || order.file_uploaded"
                                    >
                                        <template v-slot:selection="{ index, text }">
                                            <v-chip v-if="index < 2" color="deep-purple accent-4" dark label small >
                                                {{ text }}
                                            </v-chip>
                                        </template>
                                    </v-file-input>
                                </td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                    <br>
                    <v-btn @click="sendForm" :disabled="loading || orders.some(e => !e.url || !e.biller || !e.invoice_number)"><v-icon left>mdi-content-save</v-icon> Guardar</v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
export default {
    data()
    {
        return{
            orders: [],
            billersList: [],
            loading: false,
            showDialog: false,
        }
    },
    created()
    {
        this.$parent.$on('openModalCargarDtes', (orders) => {
            this.orders = [];
            this.orders = orders.map(order => ({
                ...order,
                file_uploaded: false,
                url: undefined,
                biller: undefined,
                file: undefined,
                invoice_number: undefined 
            }));
            this.showDialog = true;
        }); 
    },
    mounted()
    {
        this.fetchBillers();
    },
    methods: {
        fetchBillers(){
            var vm = this;
            this.axios({
                method: 'GET',
                url: 'invoices/billers/data_select_input'
            }).then( response => {
                vm.billersList = response.data.map(e => ({text: e.text, value: e.text}));
            }).catch( error => {
                console.log(error);
            });
        },
        uploadFile(index)
        {
            if(this.orders[index].file instanceof File && !this.orders[index].file_uploaded && !this.loading){
                console.log('se tiene que subir el archivo');
                this.loading = true;
                this.$nextTick(() => {
                    let formData = new FormData();
                    formData.append("file", this.orders[index].file, this.orders[index].file.name);
                    formData.append("order_reference", this.orders[index].order_reference);
                    formData.append("source", this.orders[index].source_id);
                    var vm = this;
                    this.axios({
                        url: 'orders/upload_dte',
                        data: formData,
                        method: 'POST'
                    }).then( response => {
                        vm.orders[index].url = response.data;
                        vm.orders[index].file_uploaded = true;
                        vm.loading = false;
                        console.log('keweaeae');
                        vm.$bvToast.toast(
                            'Archivo subido con exito',
                            {
                                title: `Notificación`,
                                variant: "success",
                                solid: true,
                                toaster: "b-toaster-bottom-center",
                            }
                        );
                        
                    }).catch( error => {
                        vm.loading = false;
                    });
                })
            }
        },
        sendForm(){
            var vm = this;
            this.axios({
                url: 'orders/create_invoices_from_load_dtes',
                method: 'POST',
                data:   {   
                    orders: this.orders.map(e => ({
                        id: e.id,
                        url: e.url,
                        biller: e.biller,
                        order_reference: e.order_reference,
                        source: e.source_id,
                        store: e.store_id,
                        invoice_number: e.invoice_number
                    }))
                }
            }).then( response => {
                vm.$bvToast.toast(
                    'Dtes cargadas con exito',
                    {
                        title: `Notificación`,
                        variant: "success",
                        solid: true,
                        toaster: "b-toaster-bottom-center",
                    }
                );
                vm.$emit('success');
                vm.showDialog = false;
            }).catch( error => {

            });
        }
    }
}
</script>